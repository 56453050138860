import React from "react";
import ClientForm from "./ClientForm";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Home Page</title>
      </Helmet>

      <ClientForm />
      <div className="container">
        <div className="section-padding text-cen">
          <h2 className="heading-006">Why Work With Me?</h2>
          <p className="sub-heading">
            Several Positive reasons to with with me.I bet you dont feel any
            disappointment working with me.
          </p>
          <br></br>
          <div className="whywork-container">
            <div className="whywork">
              <ul className="whyworkul">
                <li>
                <b>Diverse Portfolio:</b> My successful portfolio spans various industries, demonstrating my expertise in handling diverse web development projects.
                </li>
                <li>
                <b>Customized Approach:</b> I tailor my web development solutions to meet the unique requirements and objectives of each client, ensuring a personalized and effective outcome.
                </li>
                <li>
                  <b>Effective communication</b> and collaboration for seamless
                  workflow.
                </li>
                <li> <b>Seamless Communication:</b> I maintain open and responsive communication channels, ensuring that we are always on the same page and enabling a smooth and efficient workflow.</li>
                <li>
                <b>Collaborative Process:</b> I actively involve you in the development journey, valuing your ideas and feedback to create a collaborative and harmonious development experience.
                </li>
              </ul>
            </div>
            <div className="whywork">
              <ul className="whyworkul">
                <li>
                <b>Punctuality:</b> I am committed to delivering projects on time, respecting project deadlines and ensuring that you can launch your website as planned.
                </li>
                <li>
                <b>Responsive Design:</b> Utilizing responsive design principles, I create websites that adapt flawlessly to various devices, offering an optimal user experience on all platforms.
                </li>
                <li>
                <b>Continuous Learning:</b> My dedication to staying up-to-date with the latest web development trends and technologies allows me to implement innovative and cutting-edge solutions.
                </li>
                <li><b>Problem-Solving Skills:</b> I possess strong analytical and problem-solving abilities, enabling me to identify and overcome challenges efficiently during the development process.</li>
                <li>
                <b>Long-Term Partnership:</b> Beyond project completion, I aim to build a lasting working relationship, offering ongoing support, maintenance, and future enhancements for your website.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container">
          <div className="section-padding">
            <h3 className="heading-006">
              Your Idea , My Execution (3 Basic Steps)
            </h3>
            <p className="sub-heading">
              got some idea ?? and stucked how to execute the idea. Don't worry
              you are at the right website.
            </p>
            <div className="content mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div class="balloon-009">
                    <img
                      src="https://freesvg.org/img/BULB02.png"
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                    <p>
                      Let's collaborate to bring your vision to life! As a web
                      developer, I'll utilize cutting-edge technologies and
                      creative solutions to build an innovative digital platform
                      that exceeds your expectations and drives your success.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="balloon-009">
                    <img
                      src="https://freesvg.org/img/mcol_magnifying_glass.png"
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                    <p>
                      In this collaborative process, we'll meticulously evaluate
                      your idea's feasibility, market potential, and alignment
                      with your goals. Through comprehensive analysis, we'll
                      identify strengths, potential challenges, and
                      opportunities for improvement, ensuring a well-informed
                      decision-making process. The ultimate outcome will be a
                      solid plan that maximizes your idea's potential, leading
                      to successful implementation and impactful results.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="balloon-009">
                    <img
                      src="https://www.svgrepo.com/show/110875/coding.svg"
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                    <p>
                      Let's kickstart the project development phase with
                      enthusiasm and dedication. By leveraging my expertise and
                      your valuable input, we'll create a detailed project
                      roadmap, prioritize tasks, and begin the development
                      process. Regular communication, agile methodologies, and
                      iterative feedback loops will ensure a seamless
                      development journey, resulting in a remarkable end product
                      that fulfills your vision.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
