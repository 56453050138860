import React, { useState } from 'react';

const TimeSlotSelector = ({ availableTimeSlots, selectedTimeSlot, onSelect, selectedDate, selectedFullDate }) => {
  const [selectedSlot, setSelectedSlot] = useState(selectedTimeSlot);

  const handleSelect = (timeSlot) => {
    setSelectedSlot(timeSlot);
    onSelect(timeSlot);
  };

  return (
    <div className="time-slot-selector sec-pad-bottom">
      <small>Step:2</small>
      <h3 className="mb-4">Available Time Slot for {selectedDate}, {selectedFullDate}</h3>
      
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {availableTimeSlots.map((timeSlot, index) => (
          <label
            key={index}
            className={`custom-radio-label ${timeSlot === selectedSlot ? 'active' : ''}`}
          >
            <input
              type="radio"
              name="inlineRadioOptions"
              id={`inlineRadio${index}`}
              value={timeSlot}
              checked={timeSlot === selectedSlot}
              onChange={() => handleSelect(timeSlot)}
            />
            {/* Display formatted time slot */}
            {`${timeSlot.start_time}  - ${timeSlot.end_time} (${timeSlot.hostName})`}
          </label>
        ))}
      </div>
    </div>
  );
};

export default TimeSlotSelector;
