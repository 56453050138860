import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import BreadCrumb from "./layout/BreadCrumb";
import { useParams } from "react-router-dom"; // Import useParams

const BlogDetails = () => {
  const { id } = useParams(); // Use the useParams hook to access 'id'

  const [blogDetails, setBlogDetails] = useState(null);
  const breadcrumbItems = [{ text: "Home", link: "/" }, { text: "blogDetails" }];
  useEffect(() => {
    const baseUrl = "https://admin.sandesthapa.com.np/";
    const apiUrl = `${baseUrl}api/blogs/${id}`;
   
    //alert(apiUrl);

    axios
      .get(apiUrl)
      .then((response) => {
        setBlogDetails(response.data);
      })
      .catch((error) => {
        console.log("Error fetching blog details:", error);
      });
  }, [id]);

  useEffect(() => {
    // Add Bootstrap class "table table-bordered" to the table inside the blog-description div
    if (blogDetails && blogDetails.description) {
      const blogDescriptionContainer = document.createElement("div");
      blogDescriptionContainer.innerHTML = blogDetails.description;
      const tables = blogDescriptionContainer.getElementsByTagName("table");
      
      for (let i = 0; i < tables.length; i++) {
        tables[i].classList.add("table", "table-bordered");
        const headings = tables[i].getElementsByTagName("th");
        for (let j = 0; j < headings.length; j++) {
          headings[j].classList.add("bg-dark","text-white");
        }
      }
      setBlogDetails((prevDetails) => ({
        ...prevDetails,
        description: blogDescriptionContainer.innerHTML,
      }));
    }
  }, [blogDetails]);

  if (!blogDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
       <Helmet>
        <title>Sandesh Thapa | {blogDetails.title}</title>
      </Helmet>
      <div className="container">
        <BreadCrumb items={breadcrumbItems} />
      </div>
      {/* <h2>{blogDetails.title}</h2>
      <p>{blogDetails.description}</p> */}
    <div className="section-padding">
    <div className="container">
      <h2 className="heading-006 fw-700">{blogDetails.title}</h2>
      <div className="blog-description">
      <div dangerouslySetInnerHTML={{ __html: blogDetails.description }} />
      </div>
    </div>
 
      </div>

      {/* Add other details as needed */}
    </div>
  );
};

export default BlogDetails;
