import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import BreadCrumb from "./layout/BreadCrumb";
import ClientForm from "./ClientForm";
const Services = () => {
  const breadcrumbItems = [{ text: "Home", link: "/" }, { text: "Services" }];

  const [serviceLists, setServiceLists] = useState([]);

  useEffect(() => {
    // Fetch data from Laravel API endpoint

    const apiUrl = "https://admin.sandesthapa.com.np/api/services";
    //alert(apiUrl);

    axios
      .get(apiUrl)

      .then((response) => {
        setServiceLists(response.data);
        //console.log("Updated serviceLists:", response.data);
      })
      .catch((error) => {
        // alert("there is error")
        console.log("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Service Page</title>
      </Helmet>

      <div className="">
        <div className="container">
          <BreadCrumb items={breadcrumbItems} />
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="services">
            <h2 className="heading-006">Every Solution You Need</h2>
            <p className="sub-heading">
              Just Pitch your idea with us and you can sit back and relax. Rest
              upon us.
            </p>
            <div className="row">
              {serviceLists.map((service, index) => (
                <div key={index} className="col-md-4">
                  <ul className="list-011">
                    <h5 className="heading-027">{service.name}</h5>
                    {service.lists.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

{/* passing services as a props for the dropdown select option */}
<ClientForm hello={serviceLists} />
    </>
  );
};

export default Services;
