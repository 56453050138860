import React from "react";
import { Helmet } from "react-helmet";
import ClientForm from "./ClientForm";
import BreadCrumb from "./layout/BreadCrumb";
const Work = () => {
  const breadcrumbItems = [{ text: "Home", link: "/" }, { text: "Works" }];
  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Work Page</title>
      </Helmet>
      <div className="container">
        <BreadCrumb items={breadcrumbItems} />
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="works">
            <h2 className="heading-006">Recent Works</h2>
            <p className="sub-heading">
              I've added some of the recent works i've completed. Some works
              cannot be listed here as they are confidential and should not be
              disclosed.
            </p>

            <b>Posting Soon...</b>
          </div>
        </div>
      </div>

      <ClientForm />
    </>
  );
};

export default Work;
