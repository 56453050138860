import React, { useState, useEffect } from "react";
import axios from "axios";

import { Helmet } from "react-helmet";
import BreadCrumb from "./layout/BreadCrumb";
import ClientForm from "./ClientForm";
import Package from "./Package";
import { Link } from "react-router-dom";

const Pricing = () => {
  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Pricing & Packages" },
  ];

  const [PricingLists, setPricingLists] = useState([]);
  useEffect(() => {
    // fetch data from laravel api endpoint
    const baseUrl ="https://admin.sandesthapa.com.np/";
    const serviceEndpoint = "api/pricings"; // Add the specific endpoint here
    const apiUrl = baseUrl + serviceEndpoint;
    //alert(apiUrl);
    axios
      .get(apiUrl)
      .then((response) => {
        setPricingLists(response.data);
      })
      .catch((error) => {
        console.log("error in fetching packages:", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Pricing Page</title>
      </Helmet>
      <div className="">
        <div className="container">
          <BreadCrumb items={breadcrumbItems} />
          <div className="section-padding">
            <h2 className="heading-006">Packages and Pricing</h2>
            <p>
              Please be assured that I adhere to uncompromising standards of
              quality in my work. For pricing details tailored to your specific
              project requirements and scope, kindly request a personalized
              quote to receive a comprehensive breakdown of costs. Rest assured
              that your project will receive the utmost attention and dedication
              to excellence.
            </p>
            <br></br>

            <div className="packages">
              <div className="row">
                {PricingLists.map((pricing, index) => (
                  <div className="col-md-4 mb-4">
                    <div class="list-005">
                      <div>
                        <h5 className="text-center">{pricing.name}</h5> 
                        <h5 className="text-center">{pricing.price}-USD </h5>
                      </div>

                      <ul>
                        {pricing.features.map((item, itemindex) => (
                          <li key={itemindex}>{item}</li>
                        ))}
                      </ul>
                      <Link to="/"
                        className="btn btn-info text-center text-white  w-100"
                        style={{ background: "#3c8ad3", border: "none" }}
                      >
                        Pitch Now
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientForm/>
    </>
  );
};

export default Pricing;
