// Breadcrumb.js

import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ items }) => {
  return (
    <ol className="breadcrumb-003">
      {items.map((item, index) => (
        <li key={index}>
          {item.link ? (
            <Link to={item.link}>{item.text}</Link>
          ) : (
            <span>{item.text}</span>
          )}
        </li>
      ))}
    </ol>
  );
};

export default BreadCrumb;
