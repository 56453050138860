import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import ClientForm from "./ClientForm";
import BreadCrumb from "./layout/BreadCrumb";
const Blogs = () => {
  const breadcrumbItems = [{ text: "Home", link: "/" }, { text: "Blogs" }];
  
  const [blogLists, setBlogLists] = useState([]);

  useEffect(() => {
    // Fetch data from Laravel API endpoint
    const apiUrl = "https://admin.sandesthapa.com.np/api/blog-lists";
    //alert(apiUrl);

    axios
      .get(apiUrl)

      .then((response) => {
        setBlogLists(response.data);
      })
      .catch((error) => {
        // alert("there is error")
        console.log("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Blog Page</title>
      </Helmet>
      <div className="container">
        <BreadCrumb items={breadcrumbItems} />
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="Blogs">
            <h2 className="heading-006">Recent Blogs</h2>
            <p className="sub-heading">Recent Blogs</p>

            <div className="row">
            {blogLists.map((blogs, index) => (
              <div className="col-md-4 col-sm-12 bottom-margin-20">
                <div class="card blog-card">
                    <div className="zoom-card">
                      <Link to={`/blogs/${blogs.id}`}>
                        <img src={blogs.image} className="card-img-top img-fluid" alt="..." />               
                        </Link>
                  
                    </div>
                  <div class="card-body">
                    <h3 class="card-title fw-700 f-24 blog-title">  <Link to={`/blogs/${blogs.id}`}>{blogs.title}</Link></h3>

                  </div>
                </div>
              </div>
               ))}
             
            </div>
          </div>
        </div>
      </div>

      <ClientForm />
    </>
  );
};

export default Blogs;













































































































