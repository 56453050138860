import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "rgb(60 138 211)" }}
      >
        <div className="container-fluid">
          {/* Use Link with a valid 'to' attribute for navigation */}
          <Link className="navbar-brand sitename" to="/">
            Sandesh
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active f20"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link f20" to="/services">
                  Services
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link f20" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link f20" to="/experiences">
                  Experiences
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link f20" to="/works">
                  Works
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link f20" to="/blogs">
                  Blogs
                </Link>
              </li>   
              <li className="nav-item">
                <Link className="nav-link f20" to="/book-appointment" style={{background:"#0bcf8dab",borderRadius:"6px"}}>
                  Book an Appointment
                </Link>
              </li>
            </ul>
            <span class="blink_me"></span>
            <span className="text-white" type="submit">
              &nbsp; <Link to="/" className="text-white" style={{textDecoration:"none"}}>Available for Freelance</Link> 
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
