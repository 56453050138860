import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Routes instead of Switch
import "./css/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./components/Home";
import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";
import Pricing from "./components/Pricing";
import Experiences from "./components/Experiences";
import Services from "./components/Services";
import Work from "./components/Work";
import Blogs from "./components/Blogs";
import Appointment from "./components/Appointment";
import BlogDetails from "./components/BlogDetails";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          {" "}
          {/* Use Routes component instead of Switch */}
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/experiences" element={<Experiences />} />
          <Route path="/services" element={<Services />} />
          <Route path="/works" element={<Work />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetails />} />       
          <Route path="/book-appointment" element={<Appointment />} />

        </Routes>
      </Router>
      {/* <Home /> */}
      <Footer />
    </>
  );
}

export default App;
