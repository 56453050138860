import React, { useState } from "react";
import axios from "axios";
const AppointmentForm = ({

  selectedDate,
  selectedTimeSlot,
  onSubmit,
  selectedFullDate,
  hostName,
  selectedTimeSlotId,
  selectedTimeSlotEnd,
  selectedHostId,
}) => {
  const [appointmentGoal, setAppointmentGoal] = useState("");
  const [countryCode, setCountryCode] = useState('');

  const countryCodes = {
"Afghanistan": "+93",
"Albania": "+355",
"Algeria": "+213",
"American Samoa": "+1 684",
"Andorra": "+376",
"Angola": "+244",
"Anguilla": "+1 264",
"Antigua and Barbuda": "+1 268",
"Argentina": "+54",
"Armenia": "+374",
"Aruba": "+297",
"Ascension": "+247",
"Australia": "+61",
"Australian External Territories": "+672",
"Austria": "+43",
"Azerbaijan": "+994",
"Bahamas": "+1 242",
"Bahrain": "+973",
"Bangladesh": "+880",
"Barbados": "+1 246",
"Barbuda": "+1 268",
"Belarus": "+375",
"Belgium": "+32",
"Belize": "+501",
"Benin": "+229",
"Bermuda": "+1 441",
"Bhutan": "+975",
"Bolivia": "+591",
"Bosnia and Herzegovina": "+387",
"Botswana": "+267",
"Brazil": "+55",
"British Indian Ocean Territory": "+246",
"British Virgin Islands": "+1 284",
"Brunei": "+673",
"Bulgaria": "+359",
"Burkina Faso": "+226",
"Burundi": "+257",
"Cambodia": "+855",
"Cameroon": "+237",
"Canada": "+1",
"Cape Verde": "+238",
"Cayman Islands": "+ 345",
"Central African Republic": "+236",
"Chad": "+235",
"Chile": "+56",
"China": "+86",
"Christmas Island": "+61",
"Cocos-Keeling Islands": "+61",
"Colombia": "+57",
"Comoros": "+269",
"Congo": "+242",
"Congo, Dem. Rep. of (Zaire)": "+243",
"Cook Islands": "+682",
"Costa Rica": "+506",
"Croatia": "+385",
"Cuba": "+53",
"Curacao": "+599",
"Cyprus": "+537",
"Czech Republic": "+420",
"Denmark": "+45",
"Diego Garcia": "+246",
"Djibouti": "+253",
"Dominica": "+1 767",
"Dominican Republic": "+1 809",
"East Timor": "+670",
"Easter Island": "+56",
"Ecuador": "+593",
"Egypt": "+20",
"El Salvador": "+503",
"Equatorial Guinea": "+240",
"Eritrea": "+291",
"Estonia": "+372",
"Ethiopia": "+251",
"Falkland Islands": "+500",
"Faroe Islands": "+298",
"Fiji": "+679",
"Finland": "+358",
"France": "+33",
"French Antilles": "+596",
"French Guiana": "+594",
"French Polynesia": "+689",
"Gabon": "+241",
"Gambia": "+220",
"Georgia": "+995",
"Germany": "+49",
"Ghana": "+233",
"Gibraltar": "+350",
"Greece": "+30",
"Greenland": "+299",
"Grenada": "+1 473",
"Guadeloupe": "+590",
"Guam": "+1 671",
"Guatemala": "+502",
"Guinea": "+224",
"Guinea-Bissau": "+245",
"Guyana": "+595",
"Haiti": "+509",
"Honduras": "+504",
"Hong Kong SAR China": "+852",
"Hungary": "+36",
"Iceland": "+354",
"India": "+91",
"Indonesia": "+62",
"Iran": "+98",
"Iraq": "+964",
"Ireland": "+353",
"Israel": "+972",
"Italy": "+39",
"Ivory Coast": "+225",
"Jamaica": "+1 876",
"Japan": "+81",
"Jordan": "+962",
"Kazakhstan": "+7 7",
"Kenya": "+254",
"Kiribati": "+686",
"Kuwait": "+965",
"Kyrgyzstan": "+996",
"Laos": "+856",
"Latvia": "+371",
"Lebanon": "+961",
"Lesotho": "+266",
"Liberia": "+231",
"Libya": "+218",
"Liechtenstein": "+423",
"Lithuania": "+370",
"Luxembourg": "+352",
"Macau SAR China": "+853",
"Macedonia": "+389",
"Madagascar": "+261",
"Malawi": "+265",
"Malaysia": "+60",
"Maldives": "+960",
"Mali": "+223",
"Malta": "+356",
"Marshall Islands": "+692",
"Martinique": "+596",
"Mauritania": "+222",
"Mauritius": "+230",
"Mayotte": "+262",
"Mexico": "+52",
"Micronesia": "+691",
"Midway Island": "+1 808",
"Moldova": "+373",
"Monaco": "+377",
"Mongolia": "+976",
"Montenegro": "+382",
"Montserrat": "+1664",
"Morocco": "+212",
"Myanmar": "+95",
"Namibia": "+264",
"Nauru": "+674",
"Nepal": "+977",
"Netherlands": "+31",
"Netherlands Antilles": "+599",
"Nevis": "+1 869",
"New Caledonia": "+687",
"New Zealand": "+64",
"Nicaragua": "+505",
"Niger": "+227",
"Nigeria": "+234",
"Niue": "+683",
"Norfolk Island": "+672",
"North Korea": "+850",
"Northern Mariana Islands": "+1 670",
"Norway": "+47",
"Oman": "+968",
"Pakistan": "+92",
"Palau": "+680",
"Palestinian Territory": "+970",
"Panama": "+507",
"Papua New Guinea": "+675",
"Paraguay": "+595",
"Peru": "+51",
"Philippines": "+63",
"Poland": "+48",
"Portugal": "+351",
"Puerto Rico": "+1 787",
"Qatar": "+974",
"Reunion": "+262",
"Romania": "+40",
"Russia": "+7",
"Rwanda": "+250",
"Samoa": "+685",
"San Marino": "+378",
"Saudi Arabia": "+966",
"Senegal": "+221",
"Serbia": "+381",
"Seychelles": "+248",
"Sierra Leone": "+232",
"Singapore": "+65",
"Slovakia": "+421",
"Slovenia": "+386",
"Solomon Islands": "+677",
"South Africa": "+27",
"South Georgia and the South Sandwich Islands": "+500",
"South Korea": "+82",
"Spain": "+34",
"Sri Lanka": "+94",
"Sudan": "+249",
"Suriname": "+597",
"Swaziland": "+268",
"Sweden": "+46",

      // ... Add more country codes
  };

  const handleCountryChange = (event) => {
      const selectedCountry = event.target.value;
      setSelectedCountry(selectedCountry);
      setCountryCode(countryCodes[selectedCountry]);
      setFormData({
        ...formData,
        country: selectedCountry === "" ? null : selectedCountry,
      });
  };
  const [selectedCountry, setSelectedCountry] = useState('');
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    country: "",
    phone_number: "",
    preferred_service: "",
    appointment_medium: "",
    additional_notes: "",
    hostName:hostName ,
    host_id : selectedHostId,
    time_slot_id : selectedTimeSlotId,
    End_time:selectedTimeSlotEnd,
    Start_time:selectedTimeSlot,
    Date:selectedFullDate,
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the name is 'country', handle it separately
    if (name === 'country') {
      handleCountryChange(event);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // Make a POST request to the Laravel backend
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const serviceEndpoint = "api/book-appointment"; // Add the specific endpoint here
    const apiUrl = baseUrl + serviceEndpoint;

    axios
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        // Handle successful form submission, show success message, etc.
        setSuccessMessage(true);
        setFormData({
          fullname: "",
          email: "",
          country: "",
          phone_number: "",
          preferred_service: "",
          appointment_medium: "",
          additional_notes: "",
          host_id : "",
          time_slot_id : "",
        });
      })
      .catch((error) => {
        console.error(error);
        // Handle errors, show error message, etc.
        alert(
          "An error occurred while booking your appointment. Please fill the appointment form correctly."
        );
      })
      .finally(() => {
        // Set loading to false when the response is received
        setLoading(false);
      });
  };


  // alert(selectedCountry);
  return (
    <div className="appointment-form sec-pad-bottom">
      <small>Step:3</small>
      <h5>Fill Your Details and complete your appointment</h5>
      <form onSubmit={handleSubmit} className="appointment">     
        <div class="row">
          <div class="col-12 col-md-8 col-lg-8 col-xl-8">
            <div class="row align-items-center mt-4">
              <div class="col">
             
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="fullname"
                    placeholder="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
              </div>
              <div class="col">            
                       <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
              <select
                className="form-control"
                name="country"
                onChange={handleCountryChange}
                value={selectedCountry}
            >
                <option  value="" disabled>-- your country -- </option>
                <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Islands">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">
                      Congo, The Democratic Republic of The
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="Holy See (Vatican City State)">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">
                      Iran, Islamic Republic of
                    </option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea, Republic of">
                      Korea, Republic of
                    </option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova, Republic of">
                      Moldova, Republic of
                    </option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">
                      Russian Federation
                    </option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and The Grenadines">
                      Saint Vincent and The Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">
                      South Georgia and The South Sandwich Islands
                    </option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">
                      Svalbard and Jan Mayen
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">
                      Syrian Arab Republic
                    </option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">
                      Virgin Islands, British
                    </option>
                    <option value="Virgin Islands, U.S.">
                      Virgin Islands, U.S.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>
             
              <div class="col">
          

              <div className="row border-0">
                <div className="col-md-3">
                  <input className="form-control" value={countryCode} readOnly/>             
                
               
                </div>
                <div className="col-md-9">
              
              
              <input
                    type="phone_number"
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    placeholder="phone number"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
               
             
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
              
              <select
                    className="form-control"
                    id="preferred_service"
                    name="preferred_service"
                    value={formData.preferred_service}
                    onChange={handleChange}
                  >
                <option value="" disabled>-- select your desired service -- </option>

                  <option value="website development">Website Development</option>
                  <option value="System Development">System Development</option>
                  <option value="SEO Services">SEO Services</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                </select>
              </div>            
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
           
              <select
                    className="form-control"
                    id="appointment_medium"
                    name="appointment_medium"
                    value={formData.appointment_medium}
                    onChange={handleChange}
                  >
                <option value="" disabled>-- Appointment Medium -- </option>

                  <option value="Online Appointment(Zoom,Google Meet,Viber)">Online Appointment(Zoom,Google Meet,Viber)</option>
                  <option value="Inhouse Appointment">Inhouse Appointment</option>
                  
                </select>
              </div>            
            </div>
            
            <div class="col">
              <label className="text-danger mt-3">(optional)</label>
              <textarea className="form-control" placeholder="additional notes" name="additional_notes" value={formData.additional_notes}
                    onChange={handleChange}></textarea>
            </div>
            <div className="form-group row mb-3">
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-outline-info mt-3"
                    disabled={loading}
                  >
                    {/* Show the loading spinner inside the button */}
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submitting...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>

                  {successMessage ? (
                    <>
                      <div
                        class="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>Success!</strong> Thankyou for contacting will
                        catch up with you soon.
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            {/* <div class="row justify-content-start mt-4">
              <div class="col">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" />I have Read and
                    Accepted the <a href="">Terms and Conditions </a>  and i want to proceed further with my booking
                  </label>
                </div>

                <button class="btn btn-primary mt-4 mb-4">Book Appointment</button>
              </div>
            </div> */}
          </div>
          <div className="col-md-4">
          <div className="alert alert-warning">
            <div>
              <label>
                <b>Selected Appointment Date:</b>
              </label>
              <span>{selectedFullDate}</span>
            </div>
            <div>
              <label>
                <b>Selected Time Slot:</b>
              </label>
              <span>{selectedTimeSlot}</span>
            </div>
            <div>
              <label>
                <b>Appointment with:</b>
              </label>
              <span>{hostName}</span>
              hello
              <span>{selectedTimeSlotId}</span>
              <span>{selectedHostId}</span>
            </div>
            </div>
            <div className="alert alert-info">
              Please wait patiently while booking your appointment.You'll
              Receive a Appointment Booking confirmed mail one the process is
              completed.
            </div>
          
          
          </div>
        </div>
      </form>
    </div>
  );
};

export default AppointmentForm;
