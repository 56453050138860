import React from "react";
import { Helmet } from "react-helmet";
import BreadCrumb from "./layout/BreadCrumb";
import ClientForm from "./ClientForm";
const Experiences = () => {
  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Experiences" },
  ];
  return (
    <>
      <Helmet>
        <title>Sandesh Thapa | Experience Page</title>
      </Helmet>
      <div className="container">
        <BreadCrumb items={breadcrumbItems} />
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 sol-sm-12">
              <div class="box-010">
                <span>Experiences</span>
                <p>
                  2016-2021: Data Entrier (Part Time Worker),
                  CloudFactory,&nbsp;
                  <a href="https://www.cloudfactory.com/" target="_blank">
                    Website
                  </a>
                </p>
                <p>
                  2019-2023: Web Developer, Content Writer, Hosting Supporter
                  [Communicate Technology] ,&nbsp;
                  <a href="https://communicate.com.np/" target="_blank">
                    Website
                  </a>
                </p>
                <p>
                  2023-present: System Engineer, HA Holdings co.
                  ltd.(Osaka,Japan) ,&nbsp;
                  <a href="https://www.ha-holdings.co.jp/" target="_blank">
                    Website
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 sol-sm-12">
              <div class="box-010">
                <span>Education</span>
                <p>
                  Schooling: Laligurans Sec. School ,&nbsp;
                  <a
                    href="https://www.facebook.com/laligurans.school21/"
                    target="_blank"
                  >
                    Facebook Page
                  </a>
                </p>
                <p>
                  High School: Prasadi Academy ,&nbsp;
                  <a
                    href="https://www.facebook.com/prasadiacademynepal/"
                    target="_blank"
                  >
                    Facebook Page
                  </a>{" "}
                  &nbsp;
                  <a href="https://www.prasadi.edu.np/" target="_blank">
                    Website
                  </a>
                </p>
                <p>
                  Under Graduate: Nagarjuna College of IT ,&nbsp; Bachelors in
                  Information Management (BIM) &nbsp;
                  <a
                    href="https://www.facebook.com/NagarjunaCollegeOfInformationTechnology/"
                    target="_blank"
                  >
                    Facebook Page
                  </a>{" "}
                  &nbsp;
                  <a href="https://nagarjunacollege.edu.np/" target="_blank">
                    Website
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ClientForm />
    </>
  );
};

export default Experiences;
