import React, { useState, useEffect } from 'react';
import BreadCrumb from './layout/BreadCrumb';
import TimeSlotSelector from './TimeSlotSelector'; // Import the TimeSlotSelector component
import AppointmentForm from './AppointmentForm'; // Import the AppointmentForm componentuse
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';

const Appointment = () => {
  const breadcrumbItems = [
    { text: 'Home', link: '/' },
    { text: 'Book Appointment' },
  ];

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFullDate, setselectedFullDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedTimeSlotEnd, setSelectedTimeSlotEnd] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [hostName, sethostName] = useState([]);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);
  const [selectedHostId, setSelectedHostId] = useState(null);
  const [showNoSlotsWarning, setShowNoSlotsWarning] = useState(false);

const handleDateChange = async (date) => {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayOfWeek = daysOfWeek[date.getDay()];
  const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1); // Add +1 to the date
  const fullDate = localDate.toISOString().substr(0, 10);

  setSelectedDate(dayOfWeek); // Set the selected day of the week
  setselectedFullDate(fullDate); // Set the selected full date
  setSelectedTimeSlot(null);
  try {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}api/time-slots/${dayOfWeek}`;

    const response = await axios.get(apiUrl);
    const timeSlots = response.data;

    // Filter out empty slots
    const nonEmptyTimeSlots = timeSlots.filter(slot => slot.host !== null);

    if (nonEmptyTimeSlots.length > 0) {
      const formattedTimeSlots = nonEmptyTimeSlots.map(slot => {
        const hostName = slot.host ? slot.host.name : 'No Host Available';
        return {
          start_time: slot.start_time,
          end_time: slot.end_time,
          time_slot_id:slot.id,
          hostName: hostName,
          hostId: slot.host_id
         
        };
      });
     
       // Assuming the first non-empty slot has the same host name
      setShowNoSlotsWarning(false);
      setAvailableTimeSlots(formattedTimeSlots);
    } else {
      // Handle the case when there are no non-empty slots
      setShowNoSlotsWarning(true);
      console.log('No available time slots');
      sethostName('');
      setAvailableTimeSlots([]);
    }
  } catch (error) {
    console.error('Error fetching time slots:', error);
  }
  };

  const handleTimeSlotSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot.start_time);
    setSelectedTimeSlotEnd(timeSlot.end_time);
    sethostName(timeSlot.hostName);
    setSelectedTimeSlotId(timeSlot.time_slot_id); // Store the time_slot_id
    setSelectedHostId(timeSlot.hostId); // Store the host_id
  
  };
  const handleFormSubmit = (formData) => {
    // Handle form submission, e.g., display the submitted data
    console.log('Form data:', formData);
  };

  const today = new Date(); // Get current date
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

  return (
    <div className="container">
      <BreadCrumb items={breadcrumbItems} />
      <main>
        <div className="calendar section-padding">
          <small>Step:1</small>
          <h3 className="">Select Your Date Of Appointment</h3>
          <p className="sub-heading mb-3">
            Just select your suitable date and we'll show you available time slots 
          </p>
          <Calendar onChange={handleDateChange} value={selectedDate} minDate={today}/>
        </div>
        <div className="timeslots">
  {availableTimeSlots.length > 0 ? (
    <TimeSlotSelector
      availableTimeSlots={availableTimeSlots}
      selectedDate={selectedDate}
      selectedFullDate={selectedFullDate}
      onSelect={handleTimeSlotSelect}
    />
  ) : (
    <div className="alert alert-warning mb-5" role="alert">
      No available time slots for  <b>{selectedDate}, {selectedFullDate}</b>.
    </div>
  )}
</div>
        <div className="appointment-form">
          {selectedTimeSlot && (
            <AppointmentForm
              selectedDate={selectedDate}
              selectedTimeSlot={selectedTimeSlot}
              selectedTimeSlotEnd={selectedTimeSlotEnd}
              selectedFullDate={selectedFullDate}
              hostName={hostName}
              selectedTimeSlotId={selectedTimeSlotId} // Pass time_slot_id
              selectedHostId={selectedHostId} // Pass host_id
              onSubmit={handleFormSubmit}
            />
          )}
        </div>
      </main>
    </div>
  );
};


export default Appointment;
